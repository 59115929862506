







import { Component, Prop, Vue } from 'vue-property-decorator'
import { OutcomeType } from '@/store/modules/walkthrough/types/outcomes/common/outcomeType'

@Component({
  components: {}
})
export default class BackgroundOverlay extends Vue {
  public backgroundOverlayClass: string[] = [];

  @Prop({ type: Object as () => OutcomeType, required: true })
  public forOutcomeType!: OutcomeType;

  public mounted (): void {
    switch (this.forOutcomeType) {
      case OutcomeType.POSITIVE:
        this.backgroundOverlayClass = ['positive-test-result']
        break
      case OutcomeType.INCONCLUSIVE:
        this.backgroundOverlayClass = ['inconclusive-test-result']
        break
      case OutcomeType.NEGATIVE:
        this.backgroundOverlayClass = ['negative-test-result']
        break
      default:
    }
  }
}
