




















































import { Component, Vue } from 'vue-property-decorator'
import ContentArea from '@/components/common/ContentArea.vue'
import ProgressLine from '@/components/ProgressLine.vue'
import store from '../../store'
import BackgroundOverlay from '@/components/common/BackgroundOverlay.vue'
import PrimaryButton from '@/components/common/PrimaryButton.vue'
import Title from '@/components/common/page-layout/Title.vue'
import { HighlightStatus } from '@/store/modules/walkthrough/types/pre-test/highlightStatus'
import { TestOutcomeHighlight } from '@/store/modules/walkthrough/types/outcomes/resultOverview/highlights/testOutcomeHighlight'
import { OutcomeScenario } from '@/store/modules/walkthrough/types/outcomes/outcomeScenario'
import { OutcomeType } from '@/store/modules/walkthrough/types/outcomes/common/outcomeType'

@Component({
  components: {
    Title,
    BackgroundOverlay,
    ContentArea,
    ProgressLine,
    PrimaryButton
  }
})
export default class Result extends Vue {
  public resultLogoSrc = '';
  public subtitleTextKey = '';
  public descriptionTextKey = '';
  public outcomeType: OutcomeType | null = null;
  public highlights: TestOutcomeHighlight[] = [];

  public isInconclusive (): boolean {
    return this.outcomeType === OutcomeType.INCONCLUSIVE
  }

  private isScrollEnd = false

  private scrollEnd (ev: { target: HTMLDivElement }): void {
    this.isScrollEnd = (ev.target.scrollTop + ev.target.clientHeight + 10) >= ev.target.scrollHeight
  }

  created (): void {
    const outcomeScenario = store.getters['walkthrough/outcomeScenario'] as OutcomeScenario
    if (!outcomeScenario || !outcomeScenario.resultOverview) {
      // this.$router.push({ name: 'welcome' })
      return
    }

    const { resultOverview } = outcomeScenario
    this.resultLogoSrc = outcomeScenario.outcomeType.resultPageLogoSrc
    this.subtitleTextKey = resultOverview.subTitleTextKey
    this.descriptionTextKey = resultOverview.descriptionTextKey
    this.outcomeType = outcomeScenario.outcomeType
    this.highlights = resultOverview.highlights
  }

  private mounted (): void {
    if (window.innerWidth < 768) {
      this.$root.$on('mobile-go-back', () => {
        this.goBack()
      })
    }
  }

  isWarning (highlight: TestOutcomeHighlight): boolean {
    return highlight.status === HighlightStatus.warning
  }

  completeTest (): void {
    this
      .$store
      .dispatch('walkthrough/completeTest')
      .then(() => {
        let path = '0'
        if (this.outcomeType && this.outcomeType.resultPageLogoSrc && this.outcomeType.resultPageLogoSrc.includes('negative')) path = '1'
        sessionStorage.setItem('highlights', JSON.stringify(this.highlights))
        this.$router.push({ name: 'outcome', params: { path } })
      })
  }

  private goBack (): void {
    this.$router.go(-1)
  }
}
